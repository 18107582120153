<!-- DetailMasterpiecePage -->
<template>
  <div class="detail-masterpiece-page-contaner">
    <div class="masterpiece-left-arrow"
         v-show="showLeftScroll">
      <img class="masterpiece-left-scroll"
           src="@/assets/detail/right.png"
           @click="masterpieceSlideLeft"
           alt="" />
    </div>
    <div class="masterpiece-container"
         v-show="dataList && dataList.length > 0">
      <div class="masterpiece-container-item"
           v-for="(item, index) in dataList"
           :key="index"
           @click="gotoDetail(item)">
        <img :src="item.imageUrl"
             alt="" />
        <div class="recommend-container-top-detail">
          <span class="movies-title">{{  languageType == 'en' ? item.projectNameEn : item.projectName }}</span>
          <span class="movies-detail">{{ item.genres.map(genre => genre.name).join(',') }}</span>
        </div>
        <div class="top-collect">
          <!-- <img :src="item.collectionFlag == 1 ? require('@/assets/home/collect-select.jpg') : require('@/assets/home/collect-normal.jpg') "
               alt=""
               @click="topCollectClick(item, $event)"> -->
        </div>
      </div>
    </div>
    <div class="masterpiece-right-arrow"
         v-show="showRightScroll">
      <img class="masterpiece-right-scroll"
           src="@/assets/detail/left.png"
           @click="masterpieceSlideRight"
           alt="" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('auth', ['isLoggedIn'])
  },
  props: {
    detailData: {},
    dataList: {
      type: [],
      required: true
    },
  },
  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()

    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        if (newVal) {
          if (this.selectItem.collectionFlag == 0) {
            if (this.selectItem.id == this.projectId) {
              console.log('DetailPageTrailersCast-Banner-hasCollectSuccess:', newVal);

              this.dataList = this.dataList.map(item => {
                if (item.id === this.selectItem.id) {
                  console.log('Banner-匹配数据:', item.id === this.selectItem.id);
                  item.collectionFlag = 1;
                }
                return item;
              });
              this.setCollectNormalDialog()
            }
          }
        }
      }
    );
  },

  watch: {
    dataList (newVal) {
      if (newVal && newVal.length > 0) {
        this.dataList = newVal
        // if (this.isMobile) {
        //   if (this.newRelatedList && this.newRelatedList.length > 5) {
        //     this.newRelatedList = this.newRelatedList.slice(0, 5);
        //   }
        // }
        setTimeout(() => {
          this.updateScrollButtonVisibility();
        }, 2500);
      } else {
        console.log('relateList is empty or undefined');
      }
    }
  },

  data () {
    return {
      selectItem: {},
      languageType: "",
      isMobile: false,
      showLeftScroll: false,
      showRightScroll: false,
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('dialog', ['showLoginDialog']),

    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {
      this.dataList = []
      this.selectItem = {}
    },

    gotoDetail (item) {
      // if (this.detailData.id === item.id) {
      //   this.$emit('clickAndScrollToTop');
      //   return;
      // }
      // 在详情页中再次导航到另一个详情页
      // const targetPath = item.id + '-' + item.projectNameEn.trim().replace(/\s+/g, "-");
      // if (this.$route.path !== targetPath) {
      //   this.$router.push(targetPath);
      // }

      // 获取域名
      const url = new URL(window.location.href);
      const domain = url.hostname;
      console.log("url:", url);
      console.log("domain:", domain);

      // 判断域名是否符合预期
      if (domain === 'marketplace.interlib.asia') {
        // 线上地址
        console.log("生产环境");
        const host = 'https://www.interlib.asia/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('生产环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      } else if (domain === 'qa.interlib.asia') {
        // 测试地址
        console.log("测试环境");
        const host = 'http://qa.interlib.asia/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('测试环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      } else {
        // 本地地址
        console.log("本地环境");
        const host = 'http://localhost:8087/';
        const nameEn = item.projectNameEn ? item.projectNameEn : 'interlib'
        const enName = nameEn.replace(/\s+/g, '-');
        const languagePrefix = this.languageType === 'en' ? 'en/' : 'cn/';
        const url = `${host}${languagePrefix}${item.id}-${enName}`;
        console.log('本地环境url:', url);
        // 在新标签页打开
        window.open(url, '_blank');
      }
    },

    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item

        console.log("topCollectClick:", item.id);
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }

    },
    bannerClick (id, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      console.log("projectId:", id);

      // 在详情页中再次导航到另一个详情页
      const targetPath = '/detail/' + id;
      console.log("targetPath:", targetPath);
      console.log("this.$route.path:", this.$route.path);
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
    },
    masterpieceSlideLeft () {
      const container = document.querySelector('.masterpiece-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },

    masterpieceSlideRight () {
      const container = document.querySelector('.masterpiece-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },
    updateScrollButtonVisibility () {
      const container = document.querySelector('.masterpiece-container');
      const maxScrollLeft = container?.scrollWidth - container?.clientWidth;
      this.showLeftScroll = container?.scrollLeft > 0;
      this.showRightScroll = (container?.scrollLeft + 30) < maxScrollLeft;
    },

  },
  mounted () {
    const container = document.querySelector('.masterpiece-container');
    container.addEventListener('scroll', this.updateScrollButtonVisibility);
    setTimeout(() => {
      this.updateScrollButtonVisibility();
    }, 2500);
  },
  beforeDestroy () {
    const container = document.querySelector('.masterpiece-container');
    container?.removeEventListener('scroll', this.updateScrollButtonVisibility);
  },
}
</script>

<style scoped>
.detail-masterpiece-page-contaner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.masterpiece-left-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.masterpiece-right-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 5px;
}

.masterpiece-right-scroll {
  cursor: pointer;
  width: 8px;
  margin-left: 5px;
}

.masterpiece-left-scroll {
  cursor: pointer;
  width: 8px;
  margin-left: 5px;
}

.masterpiece-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.masterpiece-container-item {
  flex-shrink: 0;
  width: 130px;
  margin-right: 25px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.masterpiece-container-item::after {
  display: block;
  content: "";
  padding-top: calc(100% * (102 / 80));
}

.masterpiece-container-item:hover {
  transform: translateY(-5px);
}

.masterpiece-container-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  margin-top: 5%;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.masterpiece-container-item:hover::before {
  opacity: 1;
  border-radius: 8px;
}

.masterpiece-container-item:hover .recommend-container-top-detail span {
  animation: moveUp 0.5s ease forwards;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
.masterpiece-container-item img {
  margin-top: 5%;
  width: 100%;
  height: 95%;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 8px;
}

.masterpiece-container-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 32px;
  background-color: #26282b;
  border-radius: 0 0 8px 8px;
}

.masterpiece-container-item-detail-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
}

.masterpiece-container-item-detail-container-top {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
}

.recommend-container-top-detail {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  ); /* 渐变背景 */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 80%;
}

.top-collect {
  position: absolute;
  top: 5px;
  right: 0;

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px;
  z-index: 100;
}

.top-collect img {
  border-radius: 0;
}

.recommend-container-top-detail .movies-title {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  line-height: 1.1;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap;
}

.recommend-container-top-detail .movies-detail {
  margin-top: 4px;
  color: #9b9b9b;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1.3;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  margin-bottom: 15%;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .detail-masterpiece-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .masterpiece-left-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .masterpiece-right-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .masterpiece-right-scroll {
    cursor: pointer;
    width: 8px;
    margin-left: 5px;
  }

  .masterpiece-left-scroll {
    cursor: pointer;
    width: 8px;
    margin-left: 5px;
  }

  .masterpiece-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .masterpiece-container-item {
    flex-shrink: 0;
    width: 170px;
    margin-right: 25px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .masterpiece-container-item::after {
    display: block;
    content: "";
    padding-top: calc(100% * (102 / 80));
  }

  .masterpiece-container-item:hover {
    transform: translateY(-5px);
  }

  .masterpiece-container-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    margin-top: 5%;

    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px;
  }

  .masterpiece-container-item:hover::before {
    opacity: 1;
    border-radius: 8px;
  }

  .masterpiece-container-item:hover .recommend-container-top-detail span {
    animation: moveUp 0.5s ease forwards;
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  .masterpiece-container-item img {
    margin-top: 5%;
    width: 100%;
    height: 95%;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 8px;
  }

  .masterpiece-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 32px;
    background-color: #26282b;
    background-color: aqua;
    border-radius: 0 0 8px 8px;
  }

  .masterpiece-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 10px 15px;
  }

  .masterpiece-container-item-detail-container-top {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
  }

  .recommend-container-top-detail {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 80%;
  }

  .top-collect {
    position: absolute;
    top: 5px;
    right: 0;

    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
  }

  .recommend-container-top-detail .movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail .movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    margin-bottom: 15%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
}
</style>