<!-- DetailMidPage -->
<template>
  <div class="main-all-container">
    <div class="main-container">
      <div :class="containerClass">
        <div class="mine-container"
             :style=" menuContainerStyle ">
          <div v-for="(item, index) in menuListData"
               :key="index"
               @click="clickItem(item, index)"
               :class="{ 'selected': selectedItemIndex === index, 'unselected': selectedItemIndex !== index }">
            {{ item }}
            <img v-show="item !== $t('detail_genres')"
                 src="@/assets/detail/menu_arrow.png"
                 alt="">
          </div>
        </div>
        <div class="slider-menu-container"
             v-show="showBottomSlider">
        </div>
        <div @click="sliderClick"
             class="slider-container"
             :style=" sliderContainerStyle "
             v-show="showBottomSlider">
          <img v-show="sliderIsOpen"
               src="@/assets/detail/slider_arrow_close.png"
               alt="">
          <img v-show="!sliderIsOpen"
               src="@/assets/detail/slider_arrow_open.png"
               alt="">
        </div>
        <div v-show="sliderIsOpen"
             class="bottom-container-right">
          <div class="discover">
            <div class="history"
                 v-show="newCastsList && newCastsList.length > 0">
              <div class="history-up-arrow"
                   v-show="showUpScroll">
                <img class="history-up-scroll"
                     src="@/assets/detail/menu_arrow_up.png"
                     @click="historySlideUp"
                     alt="" />
              </div>
              <div class="history-container"
                   v-show="newCastsList && newCastsList.length > 0">
                <!-- <div :class="{ 'history-container-item': item.isClick === 1, 'history-container-item-disable': item.isClick === 0 }"
                     v-for="(item, index) in newCastsList"
                     :key="index"
                     @click="castHeadClick(item)"> -->
                <div v-for="(item, index) in newCastsList"
                     :key="index"
                     @click="castHeadClick(item)"
                     :class="{'history-container-item': item.isClick === 1, 'history-container-item-disable': item.isClick === 0}">

                  <img v-if="item.mode === 1 || item.mode === 2"
                       :src="item.imgUrl"
                       alt="" />
                  <img v-else
                       :src=avatar
                       alt="" />
                  <div class="history-container-item-img-overlay"
                       v-show="!item.isSelect">

                  </div>
                  <div class="history-container-item-detail">
                    <div class="history-container-item-detail-container">
                      <div :class="{ 'history-container-item-detail-container-top': item.isClick === 1, 'history-container-item-detail-container-top-unable': item.isClick === 0 }">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>

                  <!-- <div v-if="item.isSelect"
                       class="history-container-item-img-top">
                  </div> -->
                  <div v-if="item.isSelect && trailersMenuIsClick=== false"
                       class="history-container-item-img-top">
                  </div>

                  <img v-else-if="item.isSelect && trailersMenuIsClick===  true"
                       class="history-container-item-img-top-gif"
                       src="@/assets/detail/mid_menu_bg.gif"
                       alt="">

                  <!-- <div class="history-container-item-img-top"
                       v-show="item.isSelect">
                  </div> -->
                  <!-- <div class="history-container-item-img-top-dot"
                       v-show="item.isSelect">
                  </div> -->
                </div>
              </div>
              <div class="history-down-arrow"
                   v-show="showDownScroll">
                <img class="history-down-scroll"
                     src="@/assets/detail/menu_arrow_down.png"
                     @click="historySlideDown"
                     alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ 'right-container': !sliderIsOpen, 'right-container-big': sliderIsOpen }">
        <div class="main-mid-container-menu-container">
          <!-- 题材页面 -->
          <genres v-if="selectedItem == $t('detail_genres')"
                  :detailPageData="detailData"
                  class="main-mid-container-menu-container-genres"></genres>
          <!-- 导演页面 -->
          <cast-page v-if="selectedItem == $t('detail_director')"
                     :detailPageData="detailData"
                     :selectCastItem="selectCastItem"
                     @clickAndScrollToTop="handleScrollToTop"></cast-page>

          <!-- 编剧页面  -->
          <cast-page v-if="selectedItem == $t('detail_script_writer')"
                     :detailPageData="detailData"
                     :selectCastItem="selectCastItem"
                     @clickAndScrollToTop="handleScrollToTop"></cast-page>

          <!-- 演员页面 -->
          <cast-page v-if="selectedItem == $t('detail_cast')"
                     :detailPageData="detailData"
                     :selectCastItem="selectCastItem"
                     @clickAndScrollToTop="handleScrollToTop"></cast-page>

          <!-- 制片公司页面 -->
          <productioncompany v-if="selectedItem == $t('detail_production_ompany')"
                             :detailPageData="detailData"
                             :selectCastItem="selectCastItem"
                             @clickAndScrollToTop="handleScrollToTop"></productioncompany>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreDetailCastPage from '@/views/shopStore/StoreDetailCastPage.vue'//演员页面
import DetailGenresPage from '@/views/Detail/New/DetailGenresPage.vue'//详情题材页面
import StoreDetailProductionCompanyPage from '@/views/shopStore/StoreDetailProductionCompanyPage.vue'// 制片公司页面

import { getPresignedUrlApi } from '@/api/homeApi.js'
import { historyApi } from '@/api/common.js'
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    castPage: StoreDetailCastPage,
    genres: DetailGenresPage,
    productioncompany: StoreDetailProductionCompanyPage,
  },
  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('auth', ['isLoggedIn']),

    containerClass () {
      return {
        'bottom-container-open': this.sliderIsOpen,
        'bottom-container': !this.sliderIsOpen
      }
    },
    menuContainerStyle () {
      return {
        backgroundImage: `url("${this.backgroundImageUrl}")`
      };
    },
    sliderContainerStyle () {
      return {
        backgroundImage: `url("${this.sliderBackgroundImageUrl}")`
      };
    },
  },
  props: {
    detailData: {},
    menuListData: {
      type: Array,
      required: true
    }
  },

  watch: {
    detailData (newValue) {
      if (newValue) {
        console.log("StoreDetailMidPage-detailData:", newValue);

      }
    },
    menuListData:{
      handler: function (newValue) {
        if (newValue) {
          console.log("111111StoreDetailMidPage-menuListData:", newValue);

          // this.backgroundImageUrl = require(`@/assets/store/store_mid_menu_${this.menuListData.length}_1.png`);
          // console.log("DetailMidPage.backgroundImageUrl:", this.backgroundImageUrl);
          
          newValue.indexOf(this.$t('detail_cast')) > -1 ? this.selectedItemIndex = newValue.indexOf(this.$t('detail_cast')) : this.selectedItemIndex = 0

          console.log(123,this.$t('detail_cast'),this.selectedItemIndex)
          if (this.menuListData[0] === this.$t('detail_genres')) {
            this.showBottomSlider = false;
          } else {
            this.showBottomSlider = true;
          }

          this.selectedItem = this.menuListData[this.selectedItemIndex]; // 默认展示演员
          console.log("DetailMidPage.showBottomSlider:", this.showBottomSlider);
          this.handleDetailTypeData(this.selectedItem);

          var mediaQuery = window.matchMedia('(max-width: 1024px)');
          if (mediaQuery.matches) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
          // this.reloadLocationLanguageData()


          setTimeout(() => {
            this.clickItem(
              this.selectedItem, this.selectedItemIndex); // 默认展示演员
          }, 200);

        }
      },
      immediate: true
    }
  },
  created () {

    // console.log("DetailMidPage.menuListData:", this.menuListData);

    // this.backgroundImageUrl = require(`@/assets/store/store_mid_menu_${this.menuListData.length}_1.png`);
    // console.log("DetailMidPage.backgroundImageUrl:", this.backgroundImageUrl);

    // if (this.menuListData[0] === this.$t('detail_genres')) {
    //   this.showBottomSlider = false;
    // } else {
    //   this.showBottomSlider = true;
    // }

    // this.selectedItem = this.menuListData[2]; // 默认展示演员
    // console.log("DetailMidPage.showBottomSlider:", this.showBottomSlider);
    // this.handleDetailTypeData(this.selectedItem);

    // var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // if (mediaQuery.matches) {
    //   this.isMobile = true;
    // } else {
    //   this.isMobile = false;
    // }
    this.reloadLocationLanguageData()

    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        // 处理hasCollectSuccess的变化
        if (newVal) {
          if (this.selectItem.collectionFlag == 0) {
            if (this.collectType == 0 && this.selectItem.id == this.projectId) {
              console.log('DetailPageTrailersCast-Banner-hasCollectSuccess:', newVal);

              this.dataList = this.dataList.map(item => {
                if (item.id === this.selectItem.id) {
                  console.log('Banner-匹配数据:', item.id === this.selectItem.id);
                  item.collectionFlag = 1;
                }
                return item;
              });
              this.setCollectNormalDialog()

            } else if (this.collectType == 1 && this.selectItem.id == this.projectId) {
              console.log('DetailPageTrailersCast-Recommend-hasCollectSuccess:', newVal);

              this.newRelatedList = this.newRelatedList.map(item => {
                if (item.id === this.selectItem.id) {
                  console.log('Recommend-匹配数据:', item.id === this.selectItem.id);
                  item.collectionFlag = 1;
                }
                return item;
              });
              this.setCollectNormalDialog()
            }
          }
        }
      }
    );
    // setTimeout(() => {
    //   this.clickItem(
    //     this.selectedItem, 2); // 默认展示演员
    // }, 1000);
  },
  mounted () {
    const container = document.querySelector('.history-container');
    container?.addEventListener('scroll', this.updateScrollButtonVisibility);
    setTimeout(() => {
      this.updateScrollButtonVisibility();
    }, 2000);
  },
  beforeDestroy () {
    const container = document.querySelector('.history-container');
    container?.removeEventListener('scroll', this.updateScrollButtonVisibility);
  },

  data () {
    return {
      newCastsList: [],
      avatar: require('@/assets/detail/detail_default_avatar.jpg'),
      languageType: "",
      isMobile: false,
      selectedItemIndex: 0,
      selectedItem: '',
      sliderIsOpen: false,
      backgroundImageUrl: '', // require('@/assets/detail/mid_menu_5_1.png'),
      sliderBackgroundImageUrl: require('@/assets/detail/slider_click.png'),
      languageType: "en",
      showUpScroll: false,
      showDownScroll: true,
      showBottomSlider: false,
      selectCastItem: {},
      trailersMenuIsClick: false
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('deatilEra', ['setDeatilEra']),
    ...mapActions('dialog', ['showLoginDialog']),

    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {
    },

    gotoDetail (item) {
      if (this.detailData.id === item.id) {
        this.$emit('clickAndScrollToTop');
        return;
      }
      // 在详情页中再次导航到另一个详情页
      const targetPath = item.id + '-' + item.projectNameEn.trim().replace(/\s+/g, "-");
      if (this.$route.path !== targetPath) {
        console.log("gotoDetail-era:", item.era);
        this.setDeatilEra(item.era)
        this.$router.push(targetPath);
      }
    },

    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item
        this.collectType = 1
        console.log("topCollectClick:", item.id);
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }

    },
    clickItem (item, index) {
      this.$nextTick(() => {
        this.selectedItemIndex = index;
        this.selectedItem = item;
        this.selectCastItem = {};
        this.backgroundImageUrl = require(`@/assets/store/store_mid_menu_${this.menuListData.length}_${index + 1}.png`);

        if (this.selectedItem === this.$t('detail_genres')) {
          this.showBottomSlider = false;
          this.sliderIsOpen = false;
        } else {
          this.showBottomSlider = true;
          this.sliderIsOpen = true;
        }
        console.log("DetailMidPage.showBottomSlider:", this.showBottomSlider);
        this.handleDetailTypeData(item);
      });

    },
    sliderClick () {
      console.log("抽屉点击");
      this.sliderIsOpen = !this.sliderIsOpen;
    },
    historySlideUp () {
      const container = document.querySelector('.history-container')
      const itemHeight = container?.offsetHeight
      const scrollAmount = itemHeight

      container?.scrollBy({
        top: -scrollAmount,
        behavior: 'smooth',
      })
      setTimeout(this.updateScrollButtonVisibility, 500)
    },

    historySlideDown () {
      const container = document.querySelector('.history-container')
      const itemHeight = container?.offsetHeight
      const scrollAmount = itemHeight

      container?.scrollBy({
        top: scrollAmount,
        behavior: 'smooth',
      })
      setTimeout(this.updateScrollButtonVisibility, 500)
    },
    castHeadClick (item) {
      if (item.isClick === 0) {
        return;
      }
      this.trailersMenuIsClick = true;

      this.selectCastItem = item;
      this.selectCastItem.selectItem = this.selectedItem;
      this.newCastsList.forEach((element) => {
        element.isSelect = element === item;
      });
      item.rotateDotActive = true;
      console.log("castHeadClick:", item);
      // 更新数据模型
      setTimeout(() => {
        item.rotateDotActive = false;
      }, 1500); // 延迟1.5秒后关闭动画
      this.$forceUpdate();

      setTimeout(() => {
        this.trailersMenuIsClick = false;
      }, 2000);
    },

    updateScrollButtonVisibility () {
      const container = document.querySelector('.history-container')
      const maxScrollTop = container?.scrollHeight - container?.clientHeight
      this.showUpScroll = container?.scrollTop > 0
      this.showDownScroll = container?.scrollTop + 30 < maxScrollTop
    },

    handleDetailTypeData (selectedItem) {
      console.log("DetailCastPage-selectedItem:", selectedItem);
      if (selectedItem === this.$t('detail_director')) { //演员
        this.handleDetailData(this.detailData.directors)
      } else if (selectedItem === this.$t('detail_script_writer')) { //编剧
        this.handleDetailData(this.detailData.screenwriters)
      } else if (selectedItem === this.$t('detail_cast')) { //导演
        this.handleDetailData(this.detailData.casts)
      } else if (selectedItem === this.$t('detail_production_ompany')) { //公司简介
        this.handleDetailData(this.detailData.productionCompanies)
      }
    },

    async handleDetailData (casts) {
      if (casts && casts.length > 0) {
        const resultData = await Promise.all(
          casts.map(async (element) => {
            if (element && element.mode === 1) {
              try {
                const imageRes = await getPresignedUrlApi(element.fileId);
                if (imageRes.data.code === 200) {
                  element.imgUrl = imageRes.data.data.url;
                }
              } catch (error) {
                // 错误处理
              }
            }
            element.isSelect = false;
            element.rotateDotActive = false;
            return element;
          })
        );
        this.newCastsList = resultData.filter(Boolean);
        const selectedObject = this.newCastsList.find((item) => item.isClick === 1);
        if (selectedObject) {
          selectedObject.isSelect = true;
          this.selectCastItem = selectedObject;
        }
        this.selectCastItem.selectItem = this.selectedItem;
        console.log("selectCastItem：", this.selectCastItem);

        console.log("处理后的演员头像数据：", this.newCastsList);
        if (this.newCastsList.length < 6) {
          this.showDownScroll = false;
        }
        setTimeout(this.updateScrollButtonVisibility, 500)
      }
    },
    handleScrollToTop () {
      this.$emit('clickAndScrollToTop');
    }
  },
}
</script>

<style scoped>
.main-all-container {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.right-container {
  width: calc(100% - 204px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  margin-left: 10px;
}
.right-container-big {
  width: calc(100% - 300px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  margin-left: 10px;
}

.bottom-container {
  width: 194px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom-container-open {
  width: 290px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  animation: slideIn 0.3s ease;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mine-container {
  left: 0;
  top: 0;
  position: absolute;
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
}

.selected {
  font-weight: bold;
  color: #fff;
  text-align: left;
  cursor: pointer;
  margin: 0px 15px 0px 20px;
  height: 74px;
  white-space: normal;
  overflow-wrap: break-word;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unselected {
  font-weight: normal;
  color: #fff;
  text-align: left;
  cursor: pointer;
  margin: 0px 15px 0px 20px;
  height: 74px;
  white-space: normal;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unselected img {
  width: 8px;
  display: block;
}

.selected img {
  width: 8px;
  display: none;
}

.slider-menu-container {
  display: flex;
  flex-flow: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bottom-container-right {
  right: 0;
  top: 0;
  position: absolute;
  width: 80px;
  height: 100%;
  margin-right: 30px;
}

.slider-container {
  width: 26px;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slider-container img {
  width: 8px;
  cursor: pointer;
}

@keyframes slideIn {
  from {
    width: 194px;
  }

  to {
    width: 290px;
  }
}

@keyframes slideOut {
  from {
    width: 290px;
  }

  to {
    width: 194px;
  }
}

.discover {
  width: 100%;
  margin-top: 15px;
  height: 470px;
}

.history {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.history-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.history-container-item {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
}

.history-container-item img {
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 6px;
}

.history-container-item-disable {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
}

.history-container-item-disable img {
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 6px;
}

.history-container-item-img-overlay {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-top: 6px;
  margin-left: 12px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.history-container-item:hover .history-container-item-img-overlay {
  opacity: 0;
}

.history-container-item img {
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 6px;
}

.history-container-item-img-top {
  /* position: absolute;
  left: 0px;
  right: 0; */
  height: 50px;
  width: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  /* margin-left: 6px; */
}

.history-container-item-img-top-gif {
  /* position: absolute;
  left: 3px;
  top: -9px; */
  margin-top: -3px !important;
  height: 58px !important;
  width: 58px !important;
}

.history-container-item-img-top-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  top: 8px;
  right: 8px;
  animation: scale 1s linear 2;
  box-shadow: 0 0 6px #fff;
}

@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@keyframes rotateDot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.history-container-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  height: 30px;
  text-align: center;
}

.history-container-item-detail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
}

.history-container-item-detail-container-top {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  word-wrap: break-word;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
}

.history-container-item-detail-container-top-unable {
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  word-wrap: break-word;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
}

.history-up-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
}

.history-down-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
  margin-right: 15px;
}

.history-up-scroll {
  cursor: pointer;
  height: 10px;
  margin: 0px;
}

.history-down-scroll {
  cursor: pointer;
  height: 10px;
  margin: 0px;
}

::-webkit-scrollbar {
  background-color: transparent;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .main-all-container {
    width: 100%;
    height: 100%;
    margin: 0px;
  }

  .main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .right-container {
    width: calc(100% - 204px);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    margin-left: 10px;
  }
  .right-container-big {
    width: calc(100% - 300px);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    margin-left: 10px;
  }

  .bottom-container {
    width: 194px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bottom-container-open {
    width: 290px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    animation: slideIn 0.3s ease;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mine-container {
    left: 0;
    top: 0;
    position: absolute;
    width: 160px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
  }

  .selected {
    font-weight: bold;
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 88px;
    white-space: normal;
    overflow-wrap: break-word;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .unselected {
    font-weight: normal;
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 88px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .unselected img {
    width: 8px;
    display: block;
  }

  .selected img {
    width: 8px;
    display: none;
  }

  .slider-menu-container {
    display: flex;
    flex-flow: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .bottom-container-right {
    right: 0;
    top: 0;
    position: absolute;
    width: 80px;
    height: 100%;
    margin-right: 30px;
  }

  .slider-container {
    width: 26px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .slider-container img {
    width: 8px;
    cursor: pointer;
  }

  @keyframes slideIn {
    from {
      width: 194px;
    }

    to {
      width: 290px;
    }
  }

  @keyframes slideOut {
    from {
      width: 290px;
    }

    to {
      width: 194px;
    }
  }

  .discover {
    width: 100%;
    margin-top: 15px;
    height: 570px;
  }

  .history {
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .history-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .history-container-item {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .history-container-item img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-disable {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .history-container-item-disable img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-img-overlay {
    display: none;
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 6px;
    margin-left: 12px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .history-container-item:hover .history-container-item-img-overlay {
    opacity: 0;
  }

  .history-container-item img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-img-top {
    /* position: absolute;
    left: -3px;
    right: 0; */
    height: 50px;
    width: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    /* margin-left: 6px; */
    /* position: relative; */
  }

  .history-container-item-img-top-gif {
    /* position: absolute;
    left: 3px;
    top: -9px; */
    margin-top: -3px !important;
    height: 58px !important;
    width: 58px !important;
  }

  .history-container-item-img-top-dot {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    top: 8px;
    right: 8px;
    animation: scale 1s linear 2;
    box-shadow: 0 0 6px #fff;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

  @keyframes rotateDot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .history-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 30px;
    text-align: center;
  }

  .history-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }

  .history-container-item-detail-container-top {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    word-wrap: break-word;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
  }

  .history-container-item-detail-container-top-unable {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    word-wrap: break-word;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
  }

  .history-up-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
    margin-right: 15px;
  }

  .history-down-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 15px;
  }

  .history-up-scroll {
    cursor: pointer;
    height: 10px;
    margin: 0px;
  }

  .history-down-scroll {
    cursor: pointer;
    height: 10px;
    margin: 0px;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .main-all-container {
    width: 100%;
    height: 100%;
    margin: 0px;
  }

  .main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .right-container {
    width: calc(100% - 170px);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    margin-left: 10px;
  }
  .right-container-big {
    width: calc(100% - 240px);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    margin-left: 10px;
  }

  .bottom-container {
    width: 152px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bottom-container-open {
    width: 230px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    animation: slideIn 0.3s ease;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mine-container {
    left: 0;
    top: 0;
    position: absolute;
    width: 124px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
  }

  .selected {
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 60px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .unselected {
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 60px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
  }

  .unselected img {
    width: 8px;
    display: block;
  }

  .selected img {
    width: 8px;
    display: none;
  }

  .slider-menu-container {
    display: flex;
    flex-flow: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .bottom-container-right {
    right: 0;
    top: 0;
    position: absolute;
    width: 80px;
    height: 100%;
    margin-right: 30px;
  }

  .slider-container {
    width: 26px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .slider-container img {
    width: 8px;
    cursor: pointer;
  }

  @keyframes slideIn {
    from {
      width: 124px;
    }

    to {
      width: 230px;
    }
  }

  @keyframes slideOut {
    from {
      width: 230px;
    }

    to {
      width: 124px;
    }
  }

  .discover {
    width: 100%;
    margin-top: 15px;
    height: 370px;
    margin-left: 5px;
  }

  .history {
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .history-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .history-container-item {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .history-container-item img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-disable {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .history-container-item-disable img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-img-overlay {
    display: none;
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 6px;
    margin-left: 20px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .history-container-item:hover .history-container-item-img-overlay {
    opacity: 0;
  }

  .history-container-item img {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 6px;
  }

  .history-container-item-img-top {
    /* position: absolute;
    left: 8px;
    right: 0; */
    height: 50px;
    width: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    /* margin-left: 6px; */
  }

  .history-container-item-img-top-gif {
    /* position: absolute;
    left: 11px;
    top: -9px; */
    margin-top: -3px !important;
    height: 58px !important;
    width: 58px !important;
  }

  .history-container-item-img-top-dot {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    top: 8px;
    right: 8px;
    animation: scale 1s linear 2;
    box-shadow: 0 0 6px #fff;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

  @keyframes rotateDot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .history-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 30px;
    text-align: center;
  }

  .history-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }

  .history-container-item-detail-container-top {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    word-wrap: break-word;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
  }

  .history-container-item-detail-container-top-unable {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    word-wrap: break-word;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
  }

  .history-up-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
    margin-right: 15px;
  }

  .history-down-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 15px;
  }

  .history-up-scroll {
    cursor: pointer;
    height: 10px;
    margin: 0px;
    margin-left: 15px;
  }

  .history-down-scroll {
    cursor: pointer;
    height: 10px;
    margin: 0px;
    margin-left: 15px;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }
}
</style>