<template>
  <div class="bg-container"
       ref="container">
    <div class="left-container">
      <div v-if="newActiveElement?.mode === 1"
           class="mouseover-video-container"
           @click="handlePlay(newActiveElement)">
        <img :src="newActiveElement?.imgUrl || ''"
             alt="">
        <div class="play-button">
          <img src="@/assets/detail/pause.png"
               alt="">
        </div>
      </div>
      <div v-else-if="newActiveElement?.mode === 2"
           class="mouseover-video-container"
           @click="handlePlay(newActiveElement)">
        <video :src="newActiveElement.link"
               style="width: 100%; height: 100%; object-fit: contain; border-radius: 10px;"
               alt=""
               preload="metadata"
               :poster="newActiveElement?.imgUrl || ''"
               @loadedmetadata="setVideoCurrentTime($event, newActiveElement)" />
        <div class="play-button">
          <img src="@/assets/detail/pause.png"
               alt="">
        </div>
      </div>
    </div>
    <!-- <div class="right-container">
      <div class="small-image"
           ref="smallImage"
           @scroll="scrollFunction">
        <div v-for="(element, index) in trailersList"
             :key="index">
          <img v-if="element.mode === 1"
               :src="element.imgUrl"
               alt=""
               :class="[
							'thumbnail',
							{
								'active-thumbnail': activeIndex === index + 1
							}
						]"
               :data-index="index + 1"
               @mouseover="handleTrailersThumbnailClick" />

          <video v-else-if="element.mode === 2"
                 :src="element.link"
                 alt=""
                 preload="metadata"
                 :class="[
							'thumbnail',
							{
								'active-thumbnail': activeIndex === index + 1
							}
						]"
                 :data-index="index + 1"
                 @mouseover="handleTrailersThumbnailClick"
                 @loadedmetadata="setVideoCurrentTime($event)" />

          <p class="num-text">{{ formatIndex(index + 1) }}</p>
        </div>

        <div class="small-image-top-button"
             ref="topButton"
             @click="scrollList(-1)">
          <img :src="require('@/assets/detail/up.png')"
               alt="" />
        </div>
        <div class="small-image-bottom-button"
             ref="bottomButton"
             @click="scrollList(1)">
          <img :src="require('@/assets/detail/down.png')"
               alt="" />
        </div>
      </div>
    </div> -->
    <!-- 查看视频弹窗 -->
    <bigVideoPop ref="bigVideoPop"
                 :categoryName="categoryName"
                 :videoListData="newVideos"></bigVideoPop>
  </div>
</template>

<script>
import DetaiVideoPop from '@/views/shopStore/StoreDetaiVideoPop.vue'// 查看视频弹窗页面
import { getPresignedUrlApi } from '@/api/homeApi.js'

export default {
  props: {
    categoryName: '',
    trailers: [],
    activeElement: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  created () {
  },

  components: {
    bigVideoPop: DetaiVideoPop
  },

  data () {
    return {
      activeIndex: 1,
      newActiveElement: undefined,
      trailersList: [],
      newVideos: []
    }
  },
  watch: {
    trailers (newValue) {
      if (newValue) {
        console.log("trailers:", newValue);
        this.handleDetailTrailersData(newValue);
      }
    },
    activeElement (newValue) {
      if (newValue) {
        console.log("trailers:", newValue);
        this.newActiveElement = newValue;
      }
    },
  },
  methods: {
    async handleDetailTrailersData (trailers) {
      if (trailers) {
        const newTrailers = await Promise.all(
          trailers.map(async (element) => {
            if (element.coverId) {
              try {
                const imageRes = await getPresignedUrlApi(element.coverId);
                if (imageRes.data.code === 200) {
                  element.imgUrl = imageRes.data.data.url || "";
                  console.log("图片URL赋值成功：", imageRes.data.data.url);
                }
              } catch (error) {
                console.error("获取图片URL出错：", error);
              }
            }
            if (element.mode == 1) {
              if (element.videoId) {
                try {
                  const videoRes = await getPresignedUrlApi(element.videoId);
                  if (videoRes.data.code === 200) {
                    element.videoUrl = videoRes.data.data.url || "";
                    console.log("获取视频URL赋值成功：", videoRes.data.data.url);

                  }
                } catch (error) {
                  console.error("获取视频URL出错：", error);
                }
              }
              return element; // 将处理后的元素存入新数组
            } else if (element.mode == 2) {
              return element;
            }
          })
        );
        console.log("数据处理后trailers：", newTrailers);
        this.trailersList = newTrailers;
        // this.activeElement = this.trailersList[0]
      }
    },

    setVideoCurrentTime (event, data) {
      if(!data.imgUrl) {
        const videoElement = event.target;
        videoElement.currentTime = 5;
      }
    },

    handleTrailersThumbnailClick (e) {
      const index = e.target.getAttribute('data-index')
      this.activeIndex = Number(index)
      const activeThumbnail = document.querySelector('.active-thumbnail')
      this.$refs.container.classList.remove('hidden')
      this.activeElement = this.trailersList[index - 1]

      if (activeThumbnail) {
        activeThumbnail.classList.remove('active-thumbnail')
      }
      e.target.classList.add('active-thumbnail')
    },

    scrollList (direction) {
      const scrollDiv = this.$refs.smallImage
      if (direction > 0) {
        scrollDiv.scrollTop += scrollDiv.clientHeight
      } else {
        scrollDiv.scrollTop -= scrollDiv.clientHeight
      }
      const topButton = this.$refs.topButton
      const bottomButton = this.$refs.bottomButton
      if (scrollDiv.scrollTop === 0) {
        topButton.style.display = 'none'
      } else {
        topButton.style.display = 'block'
      }
      if (
        scrollDiv.scrollTop + scrollDiv.clientHeight ===
        scrollDiv.scrollHeight
      ) {
        bottomButton.style.display = 'none'
      } else {
        bottomButton.style.display = 'block'
      }
    },
    scrollFunction () {
      const scrollDiv = this.$refs.smallImage
      const topButton = this.$refs.topButton
      const bottomButton = this.$refs.bottomButton
      if (scrollDiv.scrollTop === 0) {
        topButton.style.display = 'none'
      } else {
        topButton.style.display = 'block'
      }
      if (
        scrollDiv.scrollTop + scrollDiv.clientHeight ===
        scrollDiv.scrollHeight
      ) {
        bottomButton.style.display = 'none'
      } else {
        bottomButton.style.display = 'block'
      }
    },
    formatIndex (index) {
      return index.toString().padStart(2, '0')
    },
    handlePlay (activeItem) {
      console.log("Play");
      let videos = this.trailersList.map(item => {
        if (item === activeItem) {
          item.isSelect = true;
          return item;
        } else {
          item.isSelect = false;
          return item;
        }
      });
      // 更新数据
      this.newVideos = videos;
      this.$refs.bigVideoPop.open()
    }
  }
}
</script>

<style scoped>
.bg-container {
  /* margin-top: 4%; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
}

.left-container {
  height: 100%;
  width: 100%;
  /* flex-grow: 1; */
  /* position: relative; */
}

.right-container {
  width: 150px;
  height: 100%;
  position: relative;
}

.container {
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: #ff036a;
}

.mouseover-video-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  background-color: #000;
  border-radius: 8px;
}

.mouseover-video-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.play-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.small-image {
  width: 150px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.thumbnail {
  width: 120px;
  height: 90px;
  opacity: 0.4;
  margin-top: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  border-radius: 13px;
  align-content: center;
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden;
  object-fit: contain;
}

.active-thumbnail {
  opacity: 1;
  object-fit: cover;
}

.small-image p {
  text-align: center;
  width: 100%;
  font-size: 13px;
  font-family: Source Han Sans SC-Normal;
  float: right;
  color: #fff;
}

.num-text {
  text-align: center;
  width: 100%;
  font-size: 13px;
  font-family: Source Han Sans SC-Normal;
  float: right;
  color: #fff;
}

.small-image-top-button {
  display: none;
  position: absolute;
  top: 10px;
  width: 150px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.small-image-top-button img {
  width: 15px;
  height: auto;
}

.small-image-bottom-button {
  display: block;
  position: absolute;
  bottom: 10px;
  width: 150px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.small-image-bottom-button img {
  width: 15px;
  height: auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}
</style>