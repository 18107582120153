/*
 * 用户日志-API
 */

import request from '@/utils/request';

//记录用户行为日志-api
/*
 * 记录用户行为日志
 * @param {Object} body
 * @returns {Promise}
 */
export function recordUserActionApi (page, loadDuration) {
  
  const sessionToken = localStorage.getItem('interlibUserSessionToken')
  const userId = localStorage.getItem('interlibId') === '0' ? null: localStorage.getItem('interlibId')
  const referer = localStorage.getItem('interlibUserReferer')
  const deviceInfo = localStorage.getItem('interlibUserDeviceInfo')
  const browserInfo = localStorage.getItem('interlibUserBrowserInfo')
  const screenInfo = localStorage.getItem('interlibUserScreenInfo')
  const deviceId = localStorage.getItem('interlibUserVisitorId')
  const country = localStorage.getItem('interlibUserCountry')
  const param = localStorage.getItem('interlibUserRefererParams')

  

  const body= {
    "sessionToken":sessionToken, 
    "userId": userId, 
    "referer": referer, 
    "page":page, 
    "deviceInfo": deviceInfo, 
    "browserInfo": browserInfo, 
    "screenInfo": screenInfo, 
    "deviceId": deviceId, 
    "country": country, 
    "loadDuration": loadDuration,
    "param":param,
  }
  
    if (referer) {
      localStorage.setItem('interlibUserReferer', '')
    }
  
   if (param) {
      localStorage.setItem('interlibUserRefererParams', '')
    }

    return request({
        url: '/api/interlib-sys/log/record_user_action',
        method: 'PUT',
        data: body
    });
}

/*
{
    "sessionToken":"IU8Y-IN79-INSE-Pwke-JE0B-SSTU-IPwd", 会话标识 必需 最长32位
    "userId":"7282191328738129", 用户ID 必需 未登录时 为游客id
    "referer":"google.com", 访问来源 必需

    "param":"{'param':'test'}", 参数 必需

    "page":"Home", 页面类型 必需
    "deviceInfo":"", 设备信息 必需
    "browserInfo":"", 浏览器信息 必需
    "screenInfo":"", 屏幕信息 必需

    "deviceId":"cxtest", 设备ID 必需
    
    "loadDuration":20, 加载时长 必需
    "country":"中国", 国家 必需
}
*/

//记录用户注册行为日志-api
/*
 * 记录用户注册行为日志
 * @param {Object} body
 * @returns {Promise}
 */
export function recordUserRegisterActionApi (actionType) {
  
  const sessionToken = localStorage.getItem('interlibUserSessionToken')
  const userId = localStorage.getItem('interlibId') === '0' ? null: localStorage.getItem('interlibId')
  const deviceId = localStorage.getItem('interlibUserVisitorId')

  const body= {
    "sessionToken":sessionToken, 
    "userId": userId, 
    "deviceId": deviceId, 
     /** 
    * 用户注册行为类型
    *  1:进入网站 2:弹出登录框 3:弹出注册框 4:点击“Code”按钮 5:点击“Sign up”按钮 6:资料填写完整 7:注册成功 
    *  -1: 登陆 (在弹出登录框后未注册且成功登陆）
    */
    "actionType":actionType,
    }
    return request({
        url: '/api/interlib-sys/log/record_user_register_action',
        method: 'PUT',
        data: body
    });
}


//记录店铺详情用户行为日志-api
/*
 * @param {Object} body
 * @returns {Promise}
 */
export function recordStoreLogActionApi (actionType, curFilmId, trailersCount, trailersNo, trailersLength, trailersPlayLength) {
  
  const userId = localStorage.getItem('interlibId') === '0' ? '0' : localStorage.getItem('interlibId')
  const deviceId = localStorage.getItem('interlibUserVisitorId')
  
  const mailFilmId = localStorage.getItem('locationStoreMailFilmId')

   /** 
  {
    "actionType":2, //*1:邮件被打开 2:项目点击 3:片花播放 4:播放结束 5:浏览结束
    "curFilmId":"", //当前影片id
    "trailersCount":2, //片花总数,
    "trailersNo":1,//当前片花序号(打开的第几个片花)
    "trailersLength":90,//片花总时长(秒)
    "trailersPlayLength":60, // 片花播放时长(秒)
    "userId":""
  }
  */
  const body = {
    "actionType": actionType,
    "userId": userId, 
    "curFilmId":curFilmId, 
    "trailersCount": trailersCount, 
    "trailersNo": trailersNo, 
    "trailersLength": trailersLength, 
    "trailersPlayLength": trailersPlayLength,
    "deviceId": deviceId,
    }
    return request({
        url: `/api/interlib-content/shop/record_log/${mailFilmId}`,
        method: 'POST',
        data: body
    });
}