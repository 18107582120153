import { render, staticRenderFns } from "./ShopDefault.vue?vue&type=template&id=573604c2&scoped=true"
import script from "./ShopDefault.vue?vue&type=script&lang=js"
export * from "./ShopDefault.vue?vue&type=script&lang=js"
import style0 from "./ShopDefault.vue?vue&type=style&index=0&id=573604c2&prod&lang=less&scoped=true"
import style1 from "./ShopDefault.vue?vue&type=style&index=1&id=573604c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573604c2",
  null
  
)

export default component.exports